import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <h2 className="text-4xl font-bold">404 Not Found</h2>
      <p className="text-xl">{t('not-found')}</p>
    </div>
  )
}

export default NotFound
